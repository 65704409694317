import React from "react";

const NotFound = () => {
  return (
    <div style={{ minHeight: "40vh" }}>
      <h1>Error 404 </h1>
      <h2>Sorry, the page is not found.</h2>
    </div>
  );
};

export default NotFound;
